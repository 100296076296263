import { inject, Injectable } from '@angular/core'
import { AppStore } from './../../store/AppStore'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Router } from '@angular/router'
import { environment } from '@environments/environment'
import { Auth as FirebaseAuth, Auth } from '@angular/fire/auth'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { catchError, firstValueFrom, of } from 'rxjs'
import { UserSession } from '@lib/interfaces/auth-user.interface'
import { AuthorizationService } from '../authorization/authorization.service'
import { IAuthorization } from '@lib/definitions/sidebar-item.definition'
/**
 * Servicio que contiene la lógica de autenticación con OAuth2
 */
@Injectable({
	providedIn: 'root',
})
export class OAuthService {
	private appStore = inject(AppStore)
	authInstance!: Auth
	EXP_TIME_MINUTES: number = 1

	constructor(
		private router: Router,
		private authFirebase: FirebaseAuth,
		private http: HttpClient,
		private authorization: AuthorizationService
	) {}

	// async verifyEmail() {
	// 	this.authFirebase.currentUser
	// 	await sendEmailVerification(this.authFirebase.currentUser!)
	// }

	private getReturnURL() {
		return window.location.hash ? window.location.href.replace(window.location.hash, '') : window.location.href
	}

	async azureAuth(email?: string) {
		const obj = {
			'Transaction-id': '1',
			Secuence: '1',
			endUser: email!,
		}
		const headers: HttpHeaders = new HttpHeaders(obj)
		try {
			// Comprobamos si devuelve 401
			const firstStep = await firstValueFrom(
				this.http
					.get(environment.proxyOIDC, {
						headers,
					})
					.pipe(
						catchError((el) => {
							return of(el.status === 401)
						})
					)
			)
			// Consultamos la URL de login del IDP
			if (firstStep) {
				const url = `${environment.proxyBaseUrl}/oidc-okta-1/login?from=${this.getReturnURL()}&apikey=${environment.clientId}`
				window.location.href = url
			}
			// await this.authorization.getAuthorizationData(email)
			// localStorage.setItem('userSession', JSON.stringify(this.authorization.authorization))
			// this.setUserData(this.authorization.authorization!)
			// this.setUserData(sesion)
			// this.setUserData({} as any)
			this.router.navigateByUrl('/home')
		} catch (error) {
			console.error('Error durante la autenticación con Azure:', error)
			// Manejo de errores
		}
	}

	async fetchIdToken(forceRefresh: boolean = false): Promise<string | null> {
		if (!this.authFirebase.currentUser) {
			return null
		}

		try {
			const idToken = await this.authFirebase.currentUser.getIdToken(forceRefresh)
			return idToken
		} catch (error) {
			console.error('Error al obtener el ID token:', error)
			return null // Propaga el error para manejarlo donde sea necesario
		}
	}

	setUserData(user: IAuthorization, idToken?: string) {
		// Aquí puedes realizar acciones adicionales con el ID token
		this.appStore.setUserSession({
			...user,
		})
		// desired_apigee_token_lifetime: this.getSessionExpiration(),
	}

	// async validateUserSignedIn(): Promise<User | null> {
	// 	return new Promise((resolve, reject) => {
	// 		try {
	// 			this.authFirebase.onAuthStateChanged((user: User | null) => {
	// 				if (!user) return resolve(null)
	// 				this.fetchIdToken().then((token) => {
	// 					if (token) this.setUserData(user, token)
	// 				})
	// 				resolve(user)
	// 			})
	// 		} catch (error) {
	// 			reject(error)
	// 		}
	// 	})
	// }

	async validateUserSession() {
		try {
			const sessionData = JSON.parse(localStorage.getItem('userSession')!)
			if (!sessionData) return false
			const res = await firstValueFrom(
				this.http.post(`${environment.proxyBaseUrl}/portalweb-oauth2-cc-pg-protected/protected`, sessionData)
			)
			this.setUserData(sessionData)
			return true
		} catch (error) {
			console.log(error)
			localStorage.clear()
			return false
		}
	}

	async refreshToken() {
		const user = this.appStore.userInfo()
		const token = await this.getNewAccessToken({
			grant_type: 'refresh_token',
			// refresh_token: user?.refresh_token!,
		})

		this.appStore.setUserSession({
			// desired_apigee_token_lifetime: '',
			// opaque_token: '',
		})
	}

	async getNewAccessToken(paramsData: { [key: string]: string }) {
		let params = new HttpParams()
		Object.keys(paramsData).forEach((key) => {
			params = params.append(key, paramsData[key])
		})

		const token = await firstValueFrom(this.http.get(`${environment.proxyBaseUrl}/oidc-okta-1/echo/get`, { params }))
		return token
	}

	async logout(omitRefresh = false) {
		try {
			// await this.authFirebase.signOut()
			localStorage.clear()
			this.appStore.signOut()
			// const isSaml = this.appStore.userInfo()?.names?.includes('saml')
			// if (isSaml)
			this.signoutAzure()
			// if (!omitRefresh) window.location.reload()
		} catch (error) {
			console.error('Error al cerrar sesión:', error)
		}
	}

	signoutAzure() {
		// Redirige al usuario a la página de inicio después de cerrar sesión en Azure AD
		const postLogoutRedirectUri = encodeURIComponent(environment.redirectUri)
		debugger
		// Redirige al usuario para cerrar la sesión en Azure AD y luego a la página de inicio
		window.location.href = `${environment.logoutUri}?post_logout_redirect_uri=${postLogoutRedirectUri}`
	}

	// Verifica si el usuario está autenticado
	isAuthenticated(): boolean {
		if (this.appStore.userInfo()?.opaque_token) {
			return true
		}

		return false
	}

	// Obtiene la fecha de expiración de la sesión del usuario
	getSessionExpiration(): string {
		const user: UserSession = JSON.parse(localStorage.getItem('userSession')!)
		const exp = Number(user?.desired_apigee_token_lifetime)
		const originalTime = exp
		const expMinutes = Number(user.expirationToken) * 60 * 1000
		const timeToSubtract = expMinutes * 60 * 1000 // 59 minutos en milisegundos
		return (originalTime - timeToSubtract).toString()
	}
}
